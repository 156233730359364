@media only screen and (max-width: 768px) {
    .iq-navbar .navbar-collapse:not(.offcanvas-collapse) .navbar-nav {
        flex-direction: column;
        float: none;
        align-items: center;
    }

    .banner-section {
        overflow: hidden;
        /* height: 980px; */
        padding: 50px 0;
    }

    .b-content-title.f-58 {
        font-size: 38px !important;
    }

    .ld-feature h2.section-title {
        font-size: 48px;
        font-weight: 800;
        text-align: center;
        margin: 0 20px 40px;
    }

    .ld-package h2.section-title {
        font-size: 58px;
        font-weight: 800;
        text-align: left;
        /* margin: 0 70px 70px; */
    }

    .b-content.p-10 {
        padding: 30px;
    }

    .subTitle {
        font-size: 18px;
        width: 100%;
        margin-top: 20px;
    }

    .store-box .b-illustration img {
        position: absolute;
        bottom: -485px;
        right: -200px;
        width: 580px;
        display: none;
    }

    .region-copyright {
        display: flex;
        flex-direction: column;
    }

    .region-copyright .left-panel {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .region-copyright .help {
        width: 100%;
        text-align: center;
        padding: 20px;
    }
}