.navbar-nav li {
    margin: 0 20px;
}

.landing-page.navbar-nav li a {
    font-weight: 700;
    color: #000 !important;
}

.landing-page a.signup-nav-link.nav-link {
    background: #000;
    color: #fff !important;
    border-radius: 20px;
    padding: 10px 20px !important;
}

.b-illustration img {
    width: 100%;
}

.banner-section {
    overflow: hidden;
    /* height: 980px; */
    padding: 100px 0;
}

.p-10 {
    padding: 70px;
}

.pt-70 {
    padding-top: 70px;
}

.f-18 {
    font-size: 21px !important;
}

.f-58 {
    font-size: 58px !important;
}

.f-68 {
    font-size: 68px !important;
}

.f-42 {
    font-size: 42px !important;
}

.line-height-1 {
    line-height: 1;
}

.b-action a {
    padding: 10px 30px;
    font-size: 18px;
    font-weight: 600;
    margin-top: 40px;
    display: inline-block;
    line-height: 28px;
    border-radius: 10px;
    box-shadow: 0px 0px 8px #0000003d;
}

.ld-feature h2.section-title {
    font-size: 58px;
    font-weight: 800;
    text-align: center;
    margin: 0 70px 70px;
}


.ld-package h2.section-title {
    font-size: 58px;
    font-weight: 800;
    text-align: left;
    /* margin: 0 70px 70px; */
}

.ld-feature,
.ld-package {
    padding: 80px 0;
}

.feature-wrap-icon {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    background: #23795e;
    color: #ffffff;
    border-radius: 50% !important;
    margin-right: 20px;
}

.feature-wrap-icon i {
    color: #fce152;
}

.feature-wrap {
    margin-bottom: 30px;
    display: flex;
    background: #3cc49a;
    padding: 20px;
    border-radius: 15px;
    min-height: 160px;
    color: #fff;
}

.feature-wrap h4 {
    font-size: 21px;
    /* color: #fce152; */
}

.feature-wrap p {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
}

.feature-wrap-content {
    width: 70%;
}

div#pricing .price-container {
    padding: 40px;
    border-radius: 20px;
    box-shadow: 24px 24px 43px #00000012;
}

.package ul {
    list-style: none;
    padding: 0;
    text-align: left;
    margin-top: 29px;
    min-height: 300px;
}

.package li {
    display: flex;
    margin-bottom: 15px;
}

.package li i {
    font-size: 14px;
    color: #ffd600;
    background: #ffdc2417;
    border-radius: 50px;
    line-height: 14px;
    margin-right: 5px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.price span {
    font-size: 28px;
    font-weight: 700;
}

.package .name {
    font-size: 28px;
    font-weight: 700;
    margin-top: 10px;
}

.package.pro {
    background: #000;
    color: #fff;
    font-weight: 700;
    padding: 20px 20px 60px;
    border-radius: 20px;
    float: right;
    position: absolute;
    width: 100%;
    top: -100px;
}

.package.pro li i {
    font-size: 14px;
    color: #ffd600;
    background: #ffdc2459;
    border-radius: 50px;
    line-height: 14px;
    margin-right: 5px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.package.pro .badge {
    text-align: right;
    width: 100%;
    margin-bottom: 20px;
}

.package.pro .badge span {
    background: #fce152;
    color: #000;
    padding: 5px 10px;
    border-radius: 50px;
}

.p-relative {
    position: relative;
}

.package .rnd-btn {
    border-radius: 30px;
}

.package {
    padding: 20px;
    background: #ffffff;
    border-radius: 20px;
}

.mb-140 {
    margin-bottom: 140px !important;
}

.mb-80 {
    margin-bottom: 80px !important;
}

.b-content p {
    line-height: normal;
    font-weight: 700;
}

/* .billing-page */

.billing-page .navbar-brand.login {
    margin-bottom: 10px !important;
}

.billing-page .package li {
    display: flex;
    margin-bottom: 10px;
}

.billing-page .package ul {
    list-style: none;
    padding: 0;
    text-align: left;
    margin-top: 25px;
    min-height: 265px;
}

.billing-page .package.pro {
    background: #000;
    color: #fff;
    font-weight: 700;
    padding: 20px 20px 40px;
    border-radius: 20px;
    float: right;
    position: absolute;
    width: 90%;
    top: -80px;
}

ul.payement-mode {
    padding: 0;
}

.payement-mode label {
    cursor: pointer;
}

.payement-mode img {
    box-shadow: 0 0 0 10px #fff0;
    transition: box-shadow 0.1s;
}

.payement-mode input {
    display: none;
}

.payement-mode input:checked+img {
    height: 70px;
    width: auto;
    padding: 5px;
    border: 5px solid #fce152;
    border-radius: 5px;
    transition: box-shadow 0.11s;
}

ul.payement-mode li {
    display: inline-block;
    margin-right: 20px;
}

.payement-mode .radio-img>input {
    display: none;
}

.payement-mode .radio-img>.image {
    height: 60px;
    width: auto;
    max-width: 100%;
    transition: all 0.3s;
}

.payement-mode .radio-img>input:checked+.image {
    height: 60px;
    width: auto;
    padding: 2px;
    border: 2px solid #fce152;
    border-radius: 5px;
    transition: height 0.3s;
}

.message ul {
    padding: 0;
}

strong.payment-plan {
    font-size: 18px;
}

strong.payment-code {
    font-size: 28px;
}

.page-loader {
    position: fixed;
    background: #fff;
    height: 100vh;
    width: 100%;
    text-align: center;
    z-index: 9999;
}

.page-loader h1 {
    font-size: 58px;
    font-weight: 800;
    color: #fce152;
}

.message ul li {
    padding-bottom: 10px;
}

.store-box .b-content ul {
    list-style: none;
    padding: 0
}

.store-box .b-content img {
    width: 250px
}

.store-box .b-illustration img {
    position: absolute;
    bottom: -485px;
    right: -200px;
    width: 580px;
}

.store-box {
    position: relative;
}

.subTitle {
    font-size: 18px;
    width: 60%;
    margin-top: 20px;
}