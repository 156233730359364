.top-h {
  margin: 140px 0 50px;
  color: #ddd;
}

.top-h img {
    border-radius: 20px
}

.detail {
    background: rgb(243 243 243);
    padding: 20px;
    border-radius: 20px;
    margin: 0 0 20px 0;
}

.detail h3 {
    font-size: 18px;
    padding-bottom: 5px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e2e2e2;
}

.detail .content ul {
    padding: 0;
    list-style: none;
}

.detail .content ul li {
    display: inline-block;
    width: 50%;
    margin-bottom: 20px
}

.detail .content ul li label {
    color: #eaba3a;
    font-weight: 600;
    margin-bottom: 5px;
}

.tocart .n-full {
    background: #eaba42;
    padding: 10px;
    width: 100%;
    color: #fff;
    font-weight:600;
    border-radius: 10px
}

.towish .n-full {
    border: 2px solid #1d1d1d;
    padding: 10px;
    width: 100%;
    color: #1d1d1d;
    display: block;
    text-align: center;
    margin: 21px 0;
    text-decoration: none;
    font-weight:600;
    border-radius: 10px;
    transition: all .4s;
}


.towish .n-full:hover {
    border: 2px solid #1d1d1d;
    background: #1d1d1d;
    padding: 10px;
    width: 100%;
    color: #fff;
    display: block;
    text-align: center;
    margin: 21px 0;
    text-decoration: none;
    font-weight:600;
    border-radius: 10px
}

.goback a, .goback button {
    background: #c8c8c89e;
    border: 4px solid #c8c8c89e;
    padding: 10px 15px;
    width: 100%;
    display: block;
    text-align: center;
    color: #534b4b!important;
    font-weight: 600;
    border-radius: 10px;
}

div#p-map-o {
    height: 835px;
    border-radius: 20px;
    overflow: hidden;
}

/* maps marker style */
/* .pin-button .circle {
    background-color: #d83b01;
    border-radius: 50%;
    color: #fff;
    height: 2.5em;
    position: relative;
    width: 2.5em;
    border: 1px solid transparent;
    transform: scale(1,2);
  } */
  
  .circleText {
    text-align: center;
    height: 50%;
    left: 50%;
    position: absolute;
    top: 50%;
    /* transform: translate(-50%, -50%); */
    transform: scale(1,2);
    width: 50%;
  }
  
  .hover {
    background-color: #0078d4;
  }


.map-pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #eaba42;
    position: absolute;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
    -webkit-animation-name: bounce;
    -moz-animation-name: bounce;
    -o-animation-name: bounce;
    -ms-animation-name: bounce;
    animation-name: bounce;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -o-animation-duration: 1s;
    -ms-animation-duration: 1s;
    animation-duration: 1s;
  }
  .map-pin:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #2f2f2f;
    position: absolute;
    border-radius: 50%;
  }
  .pin-button {
    overflow: initial !important;
    background: transparent !important;
    border-color: transparent !important;
}

.pin-button .map-pin:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 8px 0 0 -6px;
    background: #2f2f2f;
    position: absolute;
    border-radius: 50%;
}
  .pulse {
    background: rgba(0,0,0,0.2);
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    -webkit-transform: rotateX(55deg);
    -moz-transform: rotateX(55deg);
    -o-transform: rotateX(55deg);
    -ms-transform: rotateX(55deg);
    transform: rotateX(55deg);
    z-index: -2;
  }
  /* .pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    -webkit-animation: pulsate 1s ease-out;
    -moz-animation: pulsate 1s ease-out;
    -o-animation: pulsate 1s ease-out;
    -ms-animation: pulsate 1s ease-out;
    animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-box-shadow: 0 0 1px 2px #89849b;
    box-shadow: 0 0 1px 2px #89849b;
    -webkit-animation-delay: 1.1s;
    -moz-animation-delay: 1.1s;
    -o-animation-delay: 1.1s;
    -ms-animation-delay: 1.1s;
    animation-delay: 1.1s;
  } */
/* 
  .pin-button .pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -20px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    box-shadow: 0 0 1px 2px #89849b;
    animation-delay: 1.1s;
} */

  @-moz-keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
      -moz-transform: scale(0.1, 0.1);
      -o-transform: scale(0.1, 0.1);
      -ms-transform: scale(0.1, 0.1);
      transform: scale(0.1, 0.1);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    50% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      -webkit-transform: scale(1.2, 1.2);
      -moz-transform: scale(1.2, 1.2);
      -o-transform: scale(1.2, 1.2);
      -ms-transform: scale(1.2, 1.2);
      transform: scale(1.2, 1.2);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
  }
  @-webkit-keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
      -moz-transform: scale(0.1, 0.1);
      -o-transform: scale(0.1, 0.1);
      -ms-transform: scale(0.1, 0.1);
      transform: scale(0.1, 0.1);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    50% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      -webkit-transform: scale(1.2, 1.2);
      -moz-transform: scale(1.2, 1.2);
      -o-transform: scale(1.2, 1.2);
      -ms-transform: scale(1.2, 1.2);
      transform: scale(1.2, 1.2);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
  }
  @-o-keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
      -moz-transform: scale(0.1, 0.1);
      -o-transform: scale(0.1, 0.1);
      -ms-transform: scale(0.1, 0.1);
      transform: scale(0.1, 0.1);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    50% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      -webkit-transform: scale(1.2, 1.2);
      -moz-transform: scale(1.2, 1.2);
      -o-transform: scale(1.2, 1.2);
      -ms-transform: scale(1.2, 1.2);
      transform: scale(1.2, 1.2);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
  }
  @keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
      -moz-transform: scale(0.1, 0.1);
      -o-transform: scale(0.1, 0.1);
      -ms-transform: scale(0.1, 0.1);
      transform: scale(0.1, 0.1);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    50% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      -webkit-transform: scale(1.2, 1.2);
      -moz-transform: scale(1.2, 1.2);
      -o-transform: scale(1.2, 1.2);
      -ms-transform: scale(1.2, 1.2);
      transform: scale(1.2, 1.2);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
  }
  @-moz-keyframes bounce {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-2000px) rotate(-45deg);
      -moz-transform: translateY(-2000px) rotate(-45deg);
      -o-transform: translateY(-2000px) rotate(-45deg);
      -ms-transform: translateY(-2000px) rotate(-45deg);
      transform: translateY(-2000px) rotate(-45deg);
    }
    60% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(30px) rotate(-45deg);
      -moz-transform: translateY(30px) rotate(-45deg);
      -o-transform: translateY(30px) rotate(-45deg);
      -ms-transform: translateY(30px) rotate(-45deg);
      transform: translateY(30px) rotate(-45deg);
    }
    80% {
      -webkit-transform: translateY(-10px) rotate(-45deg);
      -moz-transform: translateY(-10px) rotate(-45deg);
      -o-transform: translateY(-10px) rotate(-45deg);
      -ms-transform: translateY(-10px) rotate(-45deg);
      transform: translateY(-10px) rotate(-45deg);
    }
    100% {
      -webkit-transform: translateY(0) rotate(-45deg);
      -moz-transform: translateY(0) rotate(-45deg);
      -o-transform: translateY(0) rotate(-45deg);
      -ms-transform: translateY(0) rotate(-45deg);
      transform: translateY(0) rotate(-45deg);
    }
  }
  @-webkit-keyframes bounce {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-2000px) rotate(-45deg);
      -moz-transform: translateY(-2000px) rotate(-45deg);
      -o-transform: translateY(-2000px) rotate(-45deg);
      -ms-transform: translateY(-2000px) rotate(-45deg);
      transform: translateY(-2000px) rotate(-45deg);
    }
    60% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(30px) rotate(-45deg);
      -moz-transform: translateY(30px) rotate(-45deg);
      -o-transform: translateY(30px) rotate(-45deg);
      -ms-transform: translateY(30px) rotate(-45deg);
      transform: translateY(30px) rotate(-45deg);
    }
    80% {
      -webkit-transform: translateY(-10px) rotate(-45deg);
      -moz-transform: translateY(-10px) rotate(-45deg);
      -o-transform: translateY(-10px) rotate(-45deg);
      -ms-transform: translateY(-10px) rotate(-45deg);
      transform: translateY(-10px) rotate(-45deg);
    }
    100% {
      -webkit-transform: translateY(0) rotate(-45deg);
      -moz-transform: translateY(0) rotate(-45deg);
      -o-transform: translateY(0) rotate(-45deg);
      -ms-transform: translateY(0) rotate(-45deg);
      transform: translateY(0) rotate(-45deg);
    }
  }
  @-o-keyframes bounce {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-2000px) rotate(-45deg);
      -moz-transform: translateY(-2000px) rotate(-45deg);
      -o-transform: translateY(-2000px) rotate(-45deg);
      -ms-transform: translateY(-2000px) rotate(-45deg);
      transform: translateY(-2000px) rotate(-45deg);
    }
    60% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(30px) rotate(-45deg);
      -moz-transform: translateY(30px) rotate(-45deg);
      -o-transform: translateY(30px) rotate(-45deg);
      -ms-transform: translateY(30px) rotate(-45deg);
      transform: translateY(30px) rotate(-45deg);
    }
    80% {
      -webkit-transform: translateY(-10px) rotate(-45deg);
      -moz-transform: translateY(-10px) rotate(-45deg);
      -o-transform: translateY(-10px) rotate(-45deg);
      -ms-transform: translateY(-10px) rotate(-45deg);
      transform: translateY(-10px) rotate(-45deg);
    }
    100% {
      -webkit-transform: translateY(0) rotate(-45deg);
      -moz-transform: translateY(0) rotate(-45deg);
      -o-transform: translateY(0) rotate(-45deg);
      -ms-transform: translateY(0) rotate(-45deg);
      transform: translateY(0) rotate(-45deg);
    }
  }
  @keyframes bounce {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-2000px) rotate(-45deg);
      -moz-transform: translateY(-2000px) rotate(-45deg);
      -o-transform: translateY(-2000px) rotate(-45deg);
      -ms-transform: translateY(-2000px) rotate(-45deg);
      transform: translateY(-2000px) rotate(-45deg);
    }
    60% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(30px) rotate(-45deg);
      -moz-transform: translateY(30px) rotate(-45deg);
      -o-transform: translateY(30px) rotate(-45deg);
      -ms-transform: translateY(30px) rotate(-45deg);
      transform: translateY(30px) rotate(-45deg);
    }
    80% {
      -webkit-transform: translateY(-10px) rotate(-45deg);
      -moz-transform: translateY(-10px) rotate(-45deg);
      -o-transform: translateY(-10px) rotate(-45deg);
      -ms-transform: translateY(-10px) rotate(-45deg);
      transform: translateY(-10px) rotate(-45deg);
    }
    100% {
      -webkit-transform: translateY(0) rotate(-45deg);
      -moz-transform: translateY(0) rotate(-45deg);
      -o-transform: translateY(0) rotate(-45deg);
      -ms-transform: translateY(0) rotate(-45deg);
      transform: translateY(0) rotate(-45deg);
    }
  }
  