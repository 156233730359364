div#skeleton-04 {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 400px;
    justify-content: space-around;
}

#skeleton-04 .wrapper {
    position: relative;
    width: 422px;
    height: 348px;
    margin: 0 15px;
}

.item-03-01 {
    z-index: 0;
    left: 0;
    top: 15.8px;
    position: absolute;
    width: 422px;
    height: 348px;
     background-color: rgb(255 255 255);


}

.item-03-02 {
    z-index: 1;
    left: 135.5px;
    top: 33px;
    position: absolute;
    width: 171px;
    height: 14px;
     /* background-color: rgb(228, 228, 228); */
     background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
     animation: shine-lines 2s infinite ease-out;
     background-size: 600px;

}

.item-03-03 {
    z-index: 2;
    left: 103px;
    top: 51.5px;
    position: absolute;
    width: 236px;
    height: 15px;
     /* background-color: rgb(228, 228, 228); */
     background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
     animation: shine-lines 2s infinite ease-out;
     background-size: 600px;

}

.item-03-04 {
    z-index: 3;
    left: 102px;
    top: 72.5px;
    position: absolute;
    width: 238px;
    height: 15px;
     /* background-color: rgb(228, 228, 228); */
     background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
     animation: shine-lines 2s infinite ease-out;
     background-size: 600px;

}

.item-03-05 {
    z-index: 4;
    left: 153.5px;
    top: 92.5px;
    position: absolute;
    width: 135px;
    height: 17px;
     /* background-color: rgb(228, 228, 228); */
     background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
     animation: shine-lines 2s infinite ease-out;
     background-size: 600px;

}

.item-03-06 {
    border-radius: 50%;
    z-index: 5;
    left: 153px;
    top: 151px;
    position: absolute;
    width: 134px;
    height: 134px;
     /* background-color: rgb(228, 228, 228); */
     background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
     animation: shine-lines 2s infinite ease-out;
     background-size: 600px;

}

.item-03-07 {
    z-index: 6;
    left: 152px;
    top: 299px;
    position: absolute;
    width: 138px;
    height: 16px;
     /* background-color: rgb(228, 228, 228); */
     background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
     animation: shine-lines 2s infinite ease-out;
     background-size: 600px;

}

.item-03-08 {
    z-index: 7;
    left: 184.5px;
    top: 321.5px;
    position: absolute;
    width: 69px;
    height: 17px;
     /* background-color: rgb(228, 228, 228); */
     background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
     animation: shine-lines 2s infinite ease-out;
     background-size: 600px;

}

@keyframes shine-lines {
    0% {
      background-position: -100px;
    }
    40%, 100% {
      background-position: 200px;
    }
  }