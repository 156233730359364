#skeleton-02 {
    display: flex;
}
#skeleton-02 .wrapper {
    position: relative;
    width: 23.3%;
    height: 315px;
    margin: 0 15px;
}
.item-02-01 {
    z-index: 0;
    left: 0;
    top: 15.8px;
    position: absolute;
    width: 100%;
    height: 284px;
    border-radius: 10px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

.item-02-02 {
    z-index: 1;
    left: 0;
    top: 235.5px;
    position: absolute;
    width: 100%;
    height: 65px;
    border-radius: 10px;
    /* background-color: rgb(171, 171, 171); */
    background-color: rgb(228, 228, 228);
}

.item-02-03 {
    border-radius: 50%;
    z-index: 2;
    left: 16px;
    top: 257px;
    position: absolute;
    width: 20px;
    height: 19px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

.item-02-04 {
    z-index: 3;
    left: 53px;
    top: 259.5px;
    position: absolute;
    width: 120px;
    height: 13px;
    border-radius: 10px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

.item-02-05 {
    z-index: 4;
    left: 67%;
    top: 248.5px;
    position: absolute;
    width: 28%;
    height: 35px;
    border-radius: 10px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

@keyframes shine-lines {
    0% {
      background-position: -100px;
    }
    40%, 100% {
      background-position: 200px;
    }
  }