.main-content .content-inner {
    min-height: calc(100vh - 8.6rem);
}

.footer {
    padding: 0;
}

.footer .container-fluid {
    padding: 100px 70px;
}

.left-panel img {
    width: 150px;
    margin-bottom: 0px;
    margin-right: 30px;
}

.left-panel, .center-panel, .right-panel {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.footer .list-inline-item {
    display: block;
    margin-bottom: 5px;

}

.footer .list-inline-item a{
    color: #fff;
    font-weight: 600;
    font-size: 16px;
}

.footer h3{
    font-size: 16px;
    /* padding-left: 39px; */
    font-weight: 700;
}


.footer {
    font-size: 14px;
    background: #ffffff;
    color: #000;
    font-weight: 500;
    text-align: center;
  }

  .front.footer {
    font-size: 14px;
    background: #1c1c1c;
    color: #fff;
    font-weight: 500;
    text-align: center;
}
  
  .region-copyright {
    display: flex;
    flex-direction: row;
  }
  
  .region-copyright .left-panel {
    display: flex;
    align-items: center;
  }
  
  .region-copyright .help .with-border-dark {
    background: #444040;
    border: 3px solid #444040;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
  }

.region-copyright {
    padding: 35px 55px;
}

.region-copyright .help {
    width: 50%;
    text-align: right;
  }