@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800&display=swap');
@import url("../fonts/feather/css/feather.css");
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
/* @import url('secondaryfont.css'); */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400&display=swap');

@font-face {
  font-family: 'Feather Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Feather Bold'),
    url('../fonts/feather_bold_by_typicalbro44_dee263c.woff') format('woff');
}

body {
  font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
label,
div,
li {
  font-family: 'Montserrat', sans-serif;
}

.st-bg-white {
  background: #ffffff;
}

.st-bg-black,
.rs-btn.st-bg-black {
  background: #000;
}

.st-bg-grey,
.rs-btn.st-bg-grey {
  background: #595959;
}

.st-bg-grey-most-light {
  background: #eeeeee;
}

.st-bg-grey-light,
.rs-btn.st-bg-grey-light {
  background: #b2b2b2;
}

.st-bg-yellow,
.rs-btn.st-bg-yellow {
  background: #00c49a;
}

.bg-green-most-light {
  background: #3cc49a52;
}

.rs-btn.st-bg-yellow:active,
.rs-btn:hover,
.rs-btn:focus {
  background-color: #00c49a !important;
}

.text-yellow {
  color: #00c49a;
  font-weight: 800;
}

.text-decoration-underline {
  text-decoration: underline;
}

.st-bg-red {
  background: #ff0d0d;
}

.st-bg-yellow-light {
  background: #00c49a33;
}

.st-bg-blue {
  background: #144f89;
}

.st-bg-blue-light {
  background: #20669e;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.bg--grey {
  background: #f5f5f5;
}

.navbar-brand img {
  width: 85px;
}

.text-right {
  text-align: right;
}

.sidebar-default .navbar-nav .nav-item .nav-link:not(.disabled).active,
.sidebar-default .navbar-nav .nav-item .nav-link:not(.disabled)[aria-expanded=true] {
  background-color: #00c49a;
  box-shadow: 0 10px 20px -10px rgb(239 105 55 / 42%);
  color: #000;
  font-weight: 600;
  text-decoration: none;
}

.sidebar-default .navbar-nav .nav-item .nav-link:not(.disabled):hover:not(.active):not([aria-expanded=true]) {
  background-color: #00c49a45;
  color: #00000078;
  font-weight: 600;
  box-shadow: none;
  text-decoration: none;
}

.sidebar .sidebar-toggle {
  background: #00c49a;
}

.sidebar .sidebar-toggle {
  position: absolute;
  right: -12px;
  top: 20px;
  cursor: pointer;
  background: #00c49a;
  color: #000;
  padding: 0.2rem;
  border-radius: 1rem;
  box-shadow: 0 0.125rem 0.25rem rgb(58 87 232 / 10%);
}

.item-box {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 30px 0 rgb(17 38 146 / 5%);
}

.item-box img {
  height: 200px;
  width: auto;
  margin: 20px;
}

.item-box-content {
  background: #00c49a;
  padding: 20px;
}

.item-box-content strong {
  font-size: 18px;
  display: block;
}

.rnd-btn {
  border-radius: 10px;
}

.text-white {
  font-weight: 600;
}

.item-box input {
  height: auto;
}

.p-h-2 {
  padding-right: 10px;
  padding-left: 10px;
}

.p-h-50 {
  padding-right: 50px;
  padding-left: 50px;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.rs-badge-independent,
.rs-badge-content {
  background-color: #000000 !important;
  color: #fff;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600 !important;
  line-height: 16px;
  padding: 0 5px;
}

.ft-w-600 {
  font-weight: 600;
}

.text-white-impt {
  color: #fff !important;
}

.table tbody tr.text-white-impt td {
  color: #ffffff;
  vertical-align: middle;
}

.rs-btn-primary {
  background-color: #00c49a !important;
  color: #000 !important;
  font-weight: 600 !important;
  border: none;
}

.btn-like-link {
  background: transparent;
  text-decoration: underline;
}

.mr-1 {
  margin-right: 10px;
}

.mr-2 {
  margin-right: 20px;
}

.search-option {
  margin: 20px 0;
}

.w-40 {
  width: 40%
}

span.rs-input-group-addon svg {
  font-size: 18px;
}

span.rs-input-group-addon {
  height: 100%;
}

.rs-input,
.search-option .form-control,
.form-item .form-control,
.form-control {
  background: #f4f4f4;
}

.rounded-full {
  border-radius: 50px;
}

.action-btn {
  padding: 10px;
  margin: 0 5px;
  line-height: 1px;
}

.option-action-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px;
  background: transparent;
  border-radius: 10px;
  transition: all .3s ease-in;
}

.option-action-btn i {
  font-size: 24px;
  margin-bottom: 10px;
}

.option-action-btn:hover,
.option-action-btn.active {
  background: #efefef;
  color: #000;
}

.calcul-box-header {
  background: #000000;
  padding: 20px;
  margin: 0;
}

.calcul-box-header label {
  color: #e7e7e7;
  font-weight: 600
}

.calcul-box-header p.item_price_total {
  color: #fff;
  font-weight: 800;
}

p.item_price_total {
  font-size: 28px;
  color: #000;
}

.calcul-box-content {
  padding: 20px;
  margin: 0;
}

.recap-box {
  background: #010101;
  padding: 30px;
}

.price-box {
  margin-bottom: 20px;
}

p.item-price {
  font-size: 48px;
  color: #00c49a;
  padding: 0;
  margin: 0;
  line-height: normal;
}

.price-box span {
  color: #fff;
  font-weight: 600;
}

.cash-box {
  display: flex;
  padding: 0;
  margin: 20px 0;
}

.cash-box input,
.cash-box button {
  height: 80px;
}

.cash-box input {
  margin-right: 20px;
  background: #f4f4f4;
  font-size: 28px;
}

.f-48 {
  font-size: 28px;
}

.rs-modal-content {
  padding: 0 !important;
}

.rs-modal-header {
  padding: 20px;
  background: #00c49a;
}

.rs-modal-header h4 {
  font-weight: 600;
}

.empty-list img {
  width: 100px;
}

.empty-list {
  margin-bottom: 20px;
}

.supply {
  background: #dbffe3;
  padding: 5px 10px;
  border-radius: 50px;
  color: #28a745;
  display: inline-block;
  font-size: 11px;
  font-weight: 800;
}

.cancel {
  background: #ffe9eb;
  padding: 5px 10px;
  border-radius: 50px;
  color: #dc3545;
  display: inline-block;
  font-size: 11px;
  font-weight: 800;
}

span.pending {
  background: #fffcf2;
  padding: 5px 10px;
  border-radius: 50px;
  color: #ffc107;
  display: inline-block;
  font-size: 11px;
  font-weight: 800;
}

.submit-loader {
  width: 30px;
}

.maj-notif {
  background: #000;
  display: flex;
  height: 40px;
  color: #fff;
  font-weight: 700;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 0 0 20px;
  border-radius: 5px;
}

.OrderInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commandeslist-box th:nth-child(1),
.commandeslist-box td:nth-child(1) {
  width: 5%;
}

.commandeslist-box th:nth-child(2),
.commandeslist-box td:nth-child(2) {
  width: 20%;
}

.commandeslist-box th:nth-child(3),
.commandeslist-box td:nth-child(3) {
  width: 15%;
}

.commandeslist-box th:nth-child(4),
.commandeslist-box td:nth-child(4) {
  width: 15%;
}

.commandeslist-box th:nth-child(5),
.commandeslist-box td:nth-child(5) {
  width: 15%;
}

.commandeslist-box th:nth-child(6),
.commandeslist-box td:nth-child(6) {
  width: 10%;
}

.commandeslist-box th:nth-child(7),
.commandeslist-box td:nth-child(7) {
  width: 18%;
}

.h-400 {
  height: 400px;
}

.nav-tabs {
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  justify-content: end;
}

.nav-tabs .nav-link.active {
  color: #000;
  font-weight: 600;
  background-color: #00c49a;
  border-color: #00c49a;
  box-shadow: 0 0.125rem 0.25rem #00c49a;
}

.nav-tabs .nav-link {
  margin-bottom: 0;
  color: #000;
  background: none;
  border: 0 solid transparent;
  background: #efefef;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  margin: 0 5px;
}

.sidebar .data-scrollbar {
  max-height: 85vh;
}

.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
  max-width: 1140px;
}

.f-bold {
  font-weight: 600;
}

.f-w-600 {
  font-weight: 600;
}

.m-l-1 {
  margin-left: 10px
}

.p-h-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.rs-checkbox-group-inline {
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: -10px;
  flex-wrap: wrap;
}

.manage-product-content .rs-checkbox-inline {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  margin-top: 0;
  margin-left: 10px;
  margin-bottom: 15px;
  background: #eee;
  padding: 10px;
  border-radius: 10px;
}

.rs-checkbox label {
  line-height: 1.14285714;
  margin-bottom: 0;
  font-weight: normal;
  font-weight: 600;
  cursor: pointer;
}

span.config-action-btn button {
  background: #047335;
  padding: 10px 20px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  margin-top: -5px;
}

.ventelist-box th:nth-child(1),
.ventelist-box td:nth-child(1) {
  width: 27%;
}

.ventelist-box th:nth-child(2),
.ventelist-box td:nth-child(2) {
  width: 15%;
}

.ventelist-box th:nth-child(3),
.ventelist-box td:nth-child(3) {
  width: 15%;
}

.ventelist-box th:nth-child(4),
.ventelist-box td:nth-child(4) {
  width: 15%;
}

.ventelist-box th:nth-child(5),
.ventelist-box td:nth-child(5) {
  width: 10%;
}

.ventelist-box th:nth-child(6),
.ventelist-box td:nth-child(6) {
  width: 15%;
}

.ventelist-box th:nth-child(7),
.ventelist-box td:nth-child(7) {
  width: 20%;
}

.b-1-r {
  border: 1px solid #afafaf;
  border-radius: 10px;
  overflow: hidden;
}

footer.front.footer img {
  width: 40px;
  height: auto;
  margin-right: 10px;
}

.logo-title-footer h4 {
  color: #fff;
  margin-right: 20px;
}

.boxed-box {
  width: 50%;
  margin: 3% auto 0;
}

img.img-responsive {
  width: auto;
  height: 192px;
}

.btn-style-less .item-box-content strong {
  font-size: 15px;
  display: block;
}

.success-msg img {
  width: 350px;
}

.starter-kit button.accordion-button.collapsed {
  background: #fff;
  color: #000
}

.starter-kit .accordion-button:not(.collapsed) {
  color: #000;
  background-color: #00c49a;
}

.starter-kit .accordion-body {
  padding: 1rem 1.5rem;
  background: #ffffff;
}

.starter-kit .accordion-body {
  font-size: 16px;
  color: #000000;
}

.dropdown-menu-end[data-bs-popper] {
  left: 0;
  right: auto;
}

.depot-name {
  background: #f5f5f5;
  border-radius: 10px;
}

.depot-name a,
.depot-name a:hover,
.depot-name a:focus,
.depot-name a:active {
  text-decoration: none;
  color: #000000;
}

.position-sticky-with-shadow {
  position: sticky !important;
  top: 0;
  z-index: 9;
  box-shadow: 0px 2px 4px #00000017;
}

.depot-product-page .rs-checkbox-inline {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  margin-top: 0;
  margin-left: 10px;
  margin-bottom: 15px;
  background: #eee;
  padding: 10px;
  border-radius: 10px;
}

.text-left {
  text-align: left;
}

p {
  margin: 0;
  line-height: initial;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.solde-box ul {
  padding: 0;
}

.solde-box ul li {
  display: inline-block;
  background: #efefef;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
}

.solde-box ul li .stat-box span {
  display: block;
  font-weight: 600;
}

.f-28 {
  font-size: 28px;
}

.f-98 {
  font-size: 98px;
}

.solde-box input {
  height: 40px;
  font-size: 28px;
  background: #f3f3f3;
}

.h-50 {
  min-height: 50px !important;
}

.rate-box ul {
  display: flex;
  padding: 0;
  list-style: none;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.rate-box ul li span {
  color: #f9cc00;
  font-size: 18px;
}

.rate-box ul li {
  text-align: center;
  font-size: 58px;
  width: 20%;
}

.rate-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.solde-box img {
  width: 130px;
  margin-bottom: 20px;
}

.ld-package label {
  color: #fff;
  font-weight: 600;
}

input[type="file"] {
  background: none;
  border: none;
  height: initial;
}

.gradient {
  height: 100vh;
  background: #00c49a;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  overflow: hidden;
}

.gradient .box .c {
  border-radius: 0;
  padding: 0;
  background: none;
}

.w-15 {
  width: 15%;
}

.w-30 {
  width: 30%;
}

.s25x {
  width: 25px;
  height: auto;
}


/* user account style */

.info-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.info-box-content {
  display: flex;
  flex-direction: column;
}

.info-box-content label {
  font-size: 16px;
  font-weight: 600;
}

.info-box button {
  background: transparent;
  font-weight: 600;
  text-decoration: underline;
}

.info-edit-box input,
.info-edit-box select {
  border: 1px solid #d7d7d7 !important;
  height: 60px;
  color: #000;
  background: #ededed;
  font-weight: 500;
  font-size: 16px;
}

.info-edit-box button {
  font-weight: 600;
  color: #fff;
  font-size: 16px;
  padding: 15px 20px;
}

.info-item-box {
  padding-bottom: 20px;
  border-bottom: 1px solid #dadada;
  margin-bottom: 20px;
}

.user-account-page .container {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.tab-box ul {
  padding: 0;
  margin: 0;
}

.tab-box ul li {
  display: inline-block;
}

.tab-box ul li button {
  background: #fff;
  color: #575757;
  font-weight: 500;
}

.tab-box ul li button.active {
  background: #f3f3f3;
  color: #575757;
  font-weight: 700;
}

.tab-box {
  margin: 20px;
}

.tab-box .solde-box {
  background: #f3f3f3;
  padding: 20px;
  margin: 0;
}

.tab-box .solde-box input {
  background: #fff;
}

.option-action-btn svg {
  font-size: 24px;
}