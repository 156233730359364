.cart-summary {
    margin: 25px 50px 50px;
    background: #3b3c3c;
    border-radius: 15px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
}

.cart-summary .colalign {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 40px 40px 0;
}

.colalign---o {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px 40px 0;
}

.colalign-o, .colalign----o {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px;
}

.sum-title {
    width: 50%;
}

.sum-price {
    width: 50%;
    font-family: 'Raleway';
    font-size: 18px;
}

.cart-summary .colalign div, .colalign-o div, colalign--o div, colalign---o div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
}

.colalign-full {
    width: 100%;
    padding: 15px 40px 0;
}

.counter {
    margin: 0 5px;
}

.colalign--o .option-extra {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0;
}

.colalign--o {
    background: #1d1d1d;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px;
}

.colalign--o .option-extra .option-box {
    width: 60%;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
}

.colalign--o .option-extra  span {
    font-family: 'Raleway';
    width: 40%;
    font-size: 21px;
    color: #eaba42;
    font-weight: 700;
    text-align: center;
}

.colalign--o .option-extra .option-box input {
    margin-right: 10px;
}

.colalign---o .total-box.ybg .totalttc {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-content: center;
    align-items: center;
}

.colalign---o .total-box.ybg .totalttc .sum-price {
    font-size: 28px;
}

.st-box {
    font-size: 18px;
    color: #eaba42;
    font-weight: 800;
}

.st-box .sum-price {
    font-size: 22px;
}

.totalttc .sum-title {
    width: 30%;
  }
  .totalttc .sum-price {
    width: 70%;
    text-align: right;
  }

.commande-box button {
    background: #eaba42;
    width: 100%;
    padding: 20px 10px;
    border-radius: 5px;
}

/* item custom style */
.home-container.cart-item {
    width: 100%;
    display: flex;
    overflow: auto;
    color: #000000;
    flex-direction: column;
    margin: 10px 0 0;
    border-bottom: 1px dashed #a2a2a2;
}

.home-container.cart-item:last-child {
    border-bottom: none
}

.home-container.cart-item .home-container4 {
    flex: 0 0 auto;
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.home-container.cart-item .home-container7 {
    flex: 0 0 auto;
    width: 10%;
    height: 100%;
    display: flex;
    padding: 10px;
    align-items: center;
    align-content: center;
    flex-direction: column;
    justify-content: center;
}

.home-container.cart-item .home-container6 {
    flex: 0 0 auto;
    width: 25%;
    min-height: 105px;
    display: flex;
    padding: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.colalign----o span {
    margin-top: 10px;
    color: #ec3434;
}

.cart-map-box {
    height: 100vh;
}

.edit-campagne-title {
    font-size: 16px;
    border-radius: 5px;
    height: 32px;
    line-height: 0;
    background: transparent;
}

.less-bg-yellow i {
    font-size: 24px;
    color: #eaba3a;
}

.full-bg-yellow i {
    font-size: 24px;
    color: #eaba42;
}

.full-bg-yellow {
    background: #cb0f0f0d !important;
    border: 1px solid #cb0f0f47 !important;
    border-radius: 10px !important;
    background: #eaba4208 !important;
    border: 1px solid #eaba42 !important;
    border-radius: 10px !important;
}

button.rs-input-group-addon.rs-input-group-btn.rs-btn.rs-btn-default {
    background: #d0d3d6;
    color: #fff;
}