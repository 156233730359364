.g-block-1 {position:relative;}

#skeleton-03 .wrapper {
    width: 100%;
    height: 315px;
    position: relative;
}
.cols-1 .item-02-01, .cols-2-0 .item-02-01 {
    z-index: 0;
    left: 0;
    top: 15.8px;
    position: absolute;
    width: 100%;
    height: 600px;
    border-radius: 10px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

.cols-1 .item-02-02,.cols-2-0 .item-02-02 {
    z-index: 1;
    left: 0;
    top: 550.5px;
    position: absolute;
    width: 100%;
    height: 65px;
    border-radius: 10px;
    /* background-color: rgb(171, 171, 171); */
    background-color: rgb(228, 228, 228);
}

#skeleton-03 .item-02-03 {
    border-radius: 50%;
    z-index: 2;
    left: 17px;
    top: 258px;
    position: absolute;
    width: 20px;
    height: 19px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

.cols-1 #skeleton-03 .item-02-03,.cols-2-0 #skeleton-03 .item-02-03 {
    border-radius: 50%;
    z-index: 2;
    left: 17px;
    top: 575px;
    position: absolute;
    width: 20px;
    height: 19px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

.cols-1 .item-02-04,.cols-2-0 .item-02-04 {
    z-index: 3;
    left: 73px;
    top: 579px;
    position: absolute;
    width: 40%;
    height: 13px;
    border-radius: 10px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

.item-02-05 {
    z-index: 4;
    left: 210px;
    top: 248.5px;
    position: absolute;
    width: 102px;
    height: 35px;
    border-radius: 10px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

@keyframes shine-lines {
    0% {
      background-position: -100px;
    }
    40%, 100% {
      background-position: 200px;
    }
  }

div#skeleton-03 .item-02-05 {
    display: none;
}

#skeleton-03 .item-02-01,#skeleton-03 .item-02-02 {
    width: 100%;
    left: 0;
}

#skeleton-03 .item-02-04 {
    left: 50px !important;
}

.cols-3 div#skeleton-03 .item-02-01,.cols-4 div#skeleton-03 .item-02-01 {
    height: 370px;
}

.cols-3 div#skeleton-03 .item-02-02, .cols-4 div#skeleton-03 .item-02-02 {
    top: 320px;
}

.cols-3 div#skeleton-03 .item-02-03, .cols-4 div#skeleton-03 .item-02-03 {
    top: 344px;
}

.cols-3 div#skeleton-03 .item-02-04, .cols-4 div#skeleton-03 .item-02-04 {
    top: 346px;
}

.cols-3 div#skeleton-03, .cols-4 div#skeleton-03 {
    min-height: 400px;
}

/* with data  */

.g-block-1 .cols-1 .content-commune-home {
    height: 600px;
}

.g-block-1 .content-commune-home {
    margin: 0;
}

.g-block-1 .cols-1 .content-commune-home .img-commune-home {
    width: auto;
    height: 100%;
}

.cols-1-1 > .content-commune-home {
    margin-bottom: 30px
}


.g-block-2 .cols-2-0 .content-commune-home {
    height: 600px;
}

.g-block-2 .content-commune-home {
    margin: 0;
}

.g-block-2 .cols-2-0 .content-commune-home .img-commune-home {
    width: auto;
    height: 100%;
}

.cols-2 > .content-commune-home {
    margin-bottom: 30px
}

.g-block-3 .cols-3 .content-commune-home, .g-block-3 .cols-4 .content-commune-home{
    margin: 0
}

.g-block-3 .content-commune-home .img-commune-home {
    width: auto;
    height: 370px;
}

.g-block-3 .content-commune-home .img-commune-home img{
    width: 100%
}