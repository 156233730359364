input,
select {
    font-size: 14px;
    border-radius: 6px;
    line-height: 1.5;
    padding: 5px 10px;
    transition: box-shadow 100ms ease-in, border 100ms ease-in, background-color 100ms ease-in;
    border: 2px solid #dee1e2;
    color: rgb(14, 14, 16);
    background: #dee1e2;
    display: block;
    height: 50px;
}

input:hover {
    border-color: #ccc;
}

input:focus {
    border-color: #9147ff;
    background: #fff;
}

.st-btn-md {
    height: 50px;
}

.invoice-body .form-control {
    background: #ffffff;
}

.search-input input {
    background: #fff;
    outline: none !important;
}

.btn-style-less {
    background: transparent !important;
    border: none !important;
}

.w-100 {
    width: 100%;
}