div#skeleton-05 {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
    justify-content: space-around;
}

#skeleton-05 .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
}

.item-05-01 {
    z-index: 0;
    left: 0px;
    top: -1px;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(28 28 27);


}

.item-05-02 {
    z-index: 1;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 38%;
    position: absolute;
    width: 60%;
    height: 62px;
    background-image: linear-gradient(90deg, #3d3d3d 0px, rgb(90 88 88 / 80%) 80px, #3d3d3d 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;

}

.item-05-03 {
    z-index: 2;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    position: absolute;
    width: 40%;
    height: 62px;
    background-image: linear-gradient(90deg, #3d3d3d 0px, rgb(90 88 88 / 80%) 80px, #3d3d3d 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;

}


@keyframes shine-lines {
    0% {
        background-position: -100px;
    }

    40%,
    100% {
        background-position: 200px;
    }
}