/* #skeleton {
    position: relative;
    z-index: 999;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
} */
#skeleton .wrapper {
    width: 845px;
    min-height: 195px;
    position: relative;
}
.item-01 {
    z-index: 0;
    left: 0px;
    top: 18.5px;
    position: absolute;
    width: 235px;
    height: 158px;
    border-radius: 10px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

.item-02 {
    z-index: 1;
    left: 248.5px;
    top: 17.5px;
    position: absolute;
    width: 324px;
    height: 14px;
    border-radius: 10px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

.item-03 {
    z-index: 2;
    left: 675.5px;
    top: 17.5px;
    position: absolute;
    width: 137px;
    height: 16px;
    border-radius: 10px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

.item-04 {
    z-index: 3;
    left: 249px;
    top: 41px;
    position: absolute;
    width: 159px;
    height: 11px;
    border-radius: 10px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

.item-05 {
    z-index: 5;
    left: 250px;
    top: 123.5px;
    position: absolute;
    width: 114px;
    height: 17px;
    border-radius: 10px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

.item-06 {
    z-index: 5;
    left: 249.5px;
    top: 64px;
    position: absolute;
    width: 319px;
    height: 48px;
    border-radius: 10px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

.item-07 {
    z-index: 5;
    left: 289.5px;
    top: 64px;
    position: absolute;
    width: 319px;
    height: 48px;
    border-radius: 10px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

.item-08 {
    z-index: 7;
    left: 250px;
    top: 148.5px;
    position: absolute;
    width: 150px;
    height: 17px;
    border-radius: 10px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

.item-09 {
    z-index: 8;
    left: 648.5px;
    top: 47.5px;
    position: absolute;
    width: 165px;
    height: 17px;
    border-radius: 10px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

.item-10 {
    z-index: 8;
    left: 676.5px;
    top: 109.5px;
    position: absolute;
    width: 135px;
    height: 49px;
    border-radius: 10px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

.item-11 {
    z-index: 8;
    left: 744px;
    top: 69.5px;
    position: absolute;
    width: 68px;
    height: 17px;
    border-radius: 10px;
    /* background-color: rgb(228, 228, 228); */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;
}

@keyframes shine-lines {
    0% {
      background-position: -100px;
    }
    40%, 100% {
      background-position: 200px;
    }
  }
  @keyframes shine-avatar {
    0% {
      background-position: -32px;
    }
    40%, 100% {
      background-position: 208px;
    }
  }