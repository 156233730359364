
  .button {
    color: #000000;
    display: inline-block;
    padding: 0.5rem 1rem;
    border-color: #000000;
    border-width: 1px;
    border-radius: 4px;
    background-color: #ffffff;
  }
  .input {
    color: #000000;
    cursor: auto;
    padding: 0.5rem 1rem;
    border-color: #000000;
    border-width: 1px;
    border-radius: 4px;
    background-color: #ffffff;
  }
  .textarea {
    color: #000000;
    cursor: auto;
    padding: 0.5rem;
    border-color: #000000;
    border-width: 1px;
    border-radius: 4px;
    background-color: #ffffff;
  }
  .list {
    width: 100%;
    margin: 1em 0px 1em 0px;
    display: block;
    padding: 0px 0px 0px 1.5rem;
    list-style-type: none;
    list-style-position: outside;
  }
  .list-item {
    display: list-item;
  }
  .teleport-show {
    display: flex !important;
  }
  .content {
    font-size: 16px;
    font-family: Inter;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .heading {
    font-size: 32px;
    font-family: Inter;
    font-weight: 700;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  
  .home-container {
    width: 100%;
    display: flex;
    overflow: auto;
    color:#000000;
    flex-direction: column;
    margin: 20px 0;
  }
  .home-blog {
    width: 100%;
    display: flex;
    padding: 48px;
    max-width: 1400px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-container1 {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-container2 {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-container3 {
    position: relative;
    width: 100%;
    display: flex;
    padding: 0;
    align-items: flex-start;
    border-radius: 20px;
    justify-content: flex-start;
    background-color: #ffffff;
  }
  .home-container4 {
    position: relative;
    flex: 0 0 auto;
    width: 30%;
    height: 170px;
    overflow: hidden;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0px 0px 4px #0000001f;
  }
  .home-container5 {
    flex: 0 0 auto;
    width: 45%;
    height: 100%;
    display: flex;
    padding: 10px;
    align-self: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-text {
    font-size: 16px;
    align-self: flex-start;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    line-height: 10px;
  }
  .home-text h1 {
    font-size: 16px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    line-height: initial;
}

.home-text a {display: flex;align-items: center;color: #000 !important;}

.home-text a i {
    color: #eaba42;
}

.rating {
  font-size: 16px;
  color: #eaba39;
  margin-top: 10px;
}

.home-container.one-view-selected .rating {
  color: #fff;
}

  .home-text1 {
    font-size: 11px;
    align-self: flex-start;
    margin-top: 8px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 8px;
  }
  .home-text2 {
    font-size: 12px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 8px;
    margin-bottom: 0px;
  }
  .home-text3 {
    font-size: 12px;
    align-self: flex-start;
    margin-top: 8px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 8px;
  }
  .home-container6 {
    flex: 0 0 auto;
    width: 25%;
    height: 100%;
    display: flex;
    padding: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-text4 {
    font-size: 20px;
    align-self: flex-end;
    font-style: normal;
    font-family: Raleway;
    font-weight: 600;
  }
  .home-text5 {
    align-self: flex-end;
    margin-top: 8px;
    text-align: right;
    margin-right: 0px;
    margin-bottom: 8px;
  }
  .home-text6 {
    font-size: 12px;
  }
  .home-button {
    background-color: #eaba3a;
    color: #ffffff;
    align-self: flex-end;
    border-width: 0px;
    text-transform: uppercase;
    font-weight: 600;
  }
  @media(max-width: 767px) {
    .home-blog {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
  @media(max-width: 479px) {
    .home-blog {
      padding-top: 32px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 32px;
    }
    .home-container3 {
      position: relative;
      width: 100%;
      height: 553px;
      padding: 20px;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .home-container4 {
      position: relative;
      width: 100%;
      height: 288px;
    }
    .home-container5 {
      width: 100%;
      height: 144px;
    }
    .home-container6 {
      width: 100%;
      height: 96px;
    }
  }

  /* style 02 */
  .content-commune-home {
    position: relative;
    margin: 0 15px 0 15px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 1px 1px 4px 2px #00000021;
}

.img-commune-home {
    width: auto;
    height: 284px;
}

.img-commune-home img {
    width: 100%;
    height: 100%;
    transition: .4s;
}

.title-link-commune-home {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(0,0,0,.63);
    padding: 25px 12px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.title-commune-home {
    display: inline-block;
}

.title-commune-home span {
    width: 15px;
    height: 15px;
    background: #eaba3a;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: 6px;
    margin-right: 7px;
}

.title-commune-home a,.title-commune-home a:hover {
    color: #fff;
}

.link-commune-home {
    display: inline-block;
    float: right;
    margin: 0 5px;
}

.link-commune-home a,.link-commune-home a:hover {
    color: #fff;
    border: 1px solid #fff;
    padding: 6px 25px;
    border-radius: 10px;
}

/* style 03 */

.content-type-format-home {
  margin: 0 15px 0 15px;
}

.img-type-format-home {
  height: 284px;
  overflow: hidden;
  border-radius: 15px;
  position: relative;
  margin-bottom: 20px;
  box-shadow: 1px 1px 4px 2px #00000021;
}

.img-type-format-home img {
  width: 100%;
  height:100%;
  transition: .4s;
}

.format-type-format-home {
  position: absolute;
  left: 6%;
  bottom: 15px;
}

.format-type-format-home a {
  color: #fff;
  background: #eaba3a;
  padding: 5px 8px;
  border-radius: 5px;
}

.ville-commune-type-format-home {
  border-bottom: 1px solid #f3f3f3;
  padding: 10px 0;
}

.ville-commune-type-format-home div {
  display: inline-block;
  margin-right: 5px;
}

.ville-commune-type-format-home div a {
  color: #000;
  font-weight: 600;
}

/* .ville-commune-type-format-home .etoile-panneaux-o {
  float: right;
} */

.ville-commune-type-format-home .etoile-panneaux-o i {
  color: #eaba42;
}

.cart-type-format-home i {
  padding-right: 5px;
}

.cart-type-format-home a, .cart-type-format-home a:hover {
  color: #000;
}

.cart-type-format-home {
  padding: 10px 0;
}

.content-commune-home:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transition: .4s;
}


.img-type-format-home:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transition: .4s;
}

.filter-box {
  margin-bottom: 25px;
}

.filter-box p {
  font-weight: 600;
  font-size: 16px;
}

.home-button.button.remove {
  background-color: #cb0f0f !important;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 14px;
  color: #232D42;
  line-height: initial;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.popover-header .rs-picker-toggle-read-only {
  opacity: 1;
  border: 1px solid #cec9c9 !important;
}

.carousel {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0px 0px 7px #00000024;
}

.home-image.carousel.slide .carousel-indicators {
  border-radius: 50rem;
  transform: scale(0.5);
  transition: all 400ms ease;
}

.home-image.carousel.slide .carousel-indicators .active {
  transition: all 400ms ease;
  transform: scale(0.8);
}

.rs-picker-menu {
  z-index: 9999 !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: #fff;
  color: var(--rs-calendar-date-selected-text);
  background-color: #3b3c3c;
  background-color: #3b3c3c !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.rs-calendar-table-cell-in-range::before {
  background-color: rgb(59 60 60 / 8%);
  background-color: rgb(59 60 60 / 8%) !important;
}

.rs-btn-primary.rs-btn-sm {
  color: #fff;
  color: var(--rs-btn-primary-text);
  background-color: #3b3c3c;
  background-color: #3b3c3c !important;
  border: none;
}

.rs-picker-toolbar-ranges.rs-stack {
  display: none;
}

.format-badge {
  position: absolute;
  top: 75%;
  left: 15%;
  background: #eaba3a;
  color: #fff;
  font-weight: 600;
  padding: 2px 5px;
  border-radius: 5px;
  box-shadow: 0px 0px 8px #0000003b;
}

.add-to-wish {
  font-size: 28px;
  text-shadow: 0 0 8px #000000ad;
  background: transparent;
  color: #fff;
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 8;
}

.towish .n-full-o {
  border: 2px solid #1d1d1d;
  background: #1d1d1d;
  padding: 10px;
  width: 100%;
  color: #fff;
  display: block;
  text-align: center;
  margin: 21px 0;
  text-decoration: none;
  font-weight: 600;
  border-radius: 10px;
}

.pricing-box span {
  display: block;
  text-align: right;
}

.pricing-box .pricing {
  font-size: 38px;
  font-weight: 600;
  font-family: 'Raleway';
}

.pricing-box .pricing-description {
  margin-bottom: 30px;
  margin-left: 90px;
}