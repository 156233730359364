.entityListBar {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(233, 233, 233);
    width: 320px;
    overflow: hidden auto;
}

.entityListWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.SingleEntityButton.selected {
    background: #eff0f7;
}

.SingleEntityButton {
    background: #fff;
    padding: 0;
}

span.ant-input-search.entitySearchBar {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum","tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: start;
}

input.ent-input {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: rgb(50, 51, 50);
    line-height: inherit;
    height: 69px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 35px;
    padding-right: inherit;
    border-width: 0px 0px 1px;
    border-top-style: initial;
    border-right-style: initial;
    border-left-style: initial;
    border-top-color: initial;
    border-right-color: initial;
    border-left-color: initial;
    border-image: initial;
    border-bottom-style: solid;
    border-bottom-color: rgb(233, 233, 233);
    overflow: hidden;
    background-color: rgb(255, 255, 255);
    box-shadow: none;
    border-radius: 0px;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    outline: 0px !important;
}

span.ent-input-suffix {
    right: 7px;
    left: inherit;
    position: absolute;
    top: 50%;
    z-index: 2;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    color: rgba(0,0,0,.65);
    color: rgb(186, 186, 186);
    line-height: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.SingleEntityButton {
    width: 100%;
}

.entityList {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden auto;
}

.entityListScrollbar {
    position: relative;
    overflow-x: hidden;
    width: 100%;
    height: calc(100vh - 200px);
}

.SingleEntity {
    width: 100%;
    margin: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    flex-shrink: 0;
    padding: 15px 35px 15px 20px;
    border-bottom: 1px solid rgb(233, 233, 233);
    text-align: left;
    position: relative;
    cursor: pointer;
}

.entAvatar {
    width: 35px;
    height: 35px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-right: 15px;
    margin-left: inherit;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 50%;
}

.listing.entAvatar {
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
    margin-left: inherit;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 50%;
}

.entAvatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.SingleEntityName h3 {
    font-size: 15px;
    line-height: normal;
}

button.ent-btn.sc-ifAKCX.fcfmNQ.entDeleteBtn.ent-btn-default.ent-btn-icon-only {
    width: 24px;
    height: 24px;
    background-color: transparent;
    flex-shrink: 0;
    position: absolute;
    top: 20px;
    right: 5px;
    left: inherit;
    padding: 0px;
    border: 0px;
    font-size: 14px;
    color: rgb(186, 186, 186);
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.entityBoxWrapper.ent-layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    background-color: rgb(255, 255, 255);
    border-top: 1px solid rgb(233, 233, 233);
    border-bottom: 1px solid rgb(233, 233, 233);
    border-image: initial;
    border-left: 0px;
    border-right: inherit;
    position: relative;
}

.entityBox {
    padding: 0;
    display: flex;
}

.entityControl {
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    flex-shrink: 0;
    padding: 30px;
    background-color: rgb(255, 255, 255);
}

.entityControl button:not(.add-btn) {
    font-size: 16px;
    color: rgb(45, 52, 70);
    min-width: 35px;
    height: 35px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    outline: 0px;
    padding: 0px;
    border: 1px solid rgb(233, 233, 233);
    margin-left: -1px;
    margin-right: inherit;
    cursor: pointer;
    border-radius: 0px;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.ent-btn.add-btn.ext-btn {
    background-color: rgb(219 83 99);
    color: #fff;
    font-weight: 600;
    border: 0px;
    height: 30px;
    padding: 0px 15px;
    margin-left: auto;
    margin-right: inherit;
    border-radius: 3px;
    box-shadow: 0 10px 20px -10px #db5363;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.entityBoxScrollbar {
    width: 100%;
    height: calc(100% - 145px);
    padding: 0px 35px;
    display: flex;
}

.entityCardAddForm {
    width: 100%;
}

.entityCardInfos {
    width: 100%;
    display: flex;
    flex-shrink: 0;
    -webkit-box-align: baseline;
    align-items: baseline;
    flex-direction: row;
    margin-bottom: 15px;
}

.entityInfoLabel {
    font-size: 14px;
    font-weight: 500;
    color: rgb(50, 51, 50);
    line-height: 1.5;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 15px;
    margin-left: inherit;
    text-align: left;
    min-width: 200px;
    position: relative;
}

.entityInfoLabel::after {
    content: ":";
    position: absolute;
    right: 0px;
    left: inherit;
}

#create-form {
    width: 100%;
}

.entityInfoDetails {
    width: 100%;
}

.update-form {
    width: 100%;
}

.ent-btn.ent-btn-default.save-btn {
    background: #17cb22;
    padding: 0 10px;
    color: #fff;
    font-weight: 600;
    border: 1px solid #17cb22;
}

.ent-btn.ent-btn-default.save-btn svg {
    margin-right: 5px;
}